<script>
// @ts-check
/* eslint-disable camelcase */ /* eslint-disable no-param-reassign */
import Pagination from '@/components/UIs/Pagination.vue';
import { computed, reactive } from 'vue';
import followApi from '@/apis/followApi';
import { globalState } from '@/stores/global';
import FollowerCardInfo from '@/components/users/FollowerCardInfo.vue';
import { useFollowersWithUserInfo, usePagination } from '@/hooks/query';
import { queryClient } from '@/main';

const PER_PAGE = 20;
export default {
	components: { Pagination, FollowerCardInfo },
	setup() {
		const followersQuery = useFollowersWithUserInfo(globalState.user.id);

		const { visibleList, listLength } = usePagination(
			computed(() => followersQuery.data.value || []),
			{ PER_PAGE }
		);

		return {
			status: followersQuery.status,
			globalState,
			/** @param {{ user_id: t.User['docId'], follow_user_id: t.User['docId'] }} params */
			handleToggleFollowAction: async ({ user_id, follow_user_id }) => {
				try {
					await followApi.toggleFollowUser({ user_id, follow_user_id });
				} catch (error) {
					console.log(error);
				} finally {
					queryClient.invalidateQueries('follows:followers');
				}
			},
			listLength,
			visibleList,
			PER_PAGE
		};
	}
};
</script>

<template>
	<div class="flex flex-col flex-1 text-xl font-medium">
		<div class="w-full">
			<div class="p-4 text-2xl font-bold lg:my-16 lg:text-33px lg:ml-14">フォロワー</div>
			<div>
				<!--  -->
				<div class="w-full">
					<follower-card-info v-for="follower in visibleList" :key="follower.docId" :follower="follower" :login-user="globalState.user" @handleToggleFollowAction="handleToggleFollowAction">
					</follower-card-info>
				</div>
				<!--  -->

				<div v-if="status === 'success' && !listLength" class="m-3 text-center">
					データがありません
				</div>
				<pagination v-if="listLength" goto-route-name="follower_list" :per-page="PER_PAGE" :total-record="listLength" class="mb-8" />
			</div>
		</div>
	</div>
</template>
